import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { tap, take, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {

  constructor(private authService: AuthService, private router: Router) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    console.log('canLoad');
    return this.authService.userIsAuthenticated.pipe(
      take(1),
      switchMap(isAuthenticated => {
        if(!isAuthenticated) {
          //return this.authService.autoLogin();
          return of(isAuthenticated);
        } else {
          return of(isAuthenticated);
      }
    }),
      tap( isAuthenticated => {
        if ( !isAuthenticated) {
          console.log('not authenticate');
          this.router.navigateByUrl('/auth');
        }
    }));
  }


}
