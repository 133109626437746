import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { User } from '../components/user.models';
import { BehaviorSubject, from } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Plugins } from '@capacitor/core';

export interface AuthResponseData {
  kind: string;
  idToken: string;
  email: string;
  refreshToken: string;
  localId: string;
  expiresIn: string;
  registered?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _user = new BehaviorSubject<User>(null);

  get userIsAuthenticated() {
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          console.log('Good');
          return !!user.token;
        } else {
          console.log('Bad');
          return false;
        }
      }));
  }

  get userId() {
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return user.id;
        } else {
          return null;
        }
    }));
  }

  constructor(private http: HttpClient) { }

  autoLogin() {
    return from( Plugins.Storage.get({key: 'authData'})).pipe(
      map(storedData => {
      if( !storedData || !storedData.value) {
        return null;
      }
      const parsedData = JSON.parse(storedData.value) as {token: string; tokenExpirationDate: string; userId: string; email: string};
      const expirationTime = new Date(parsedData.tokenExpirationDate);
      if( expirationTime <= new Date()) {
        return null;
      }
      const user = new User(parsedData.userId, parsedData.email, parsedData.token, expirationTime);
      return user;
    }), tap(user => {
      if (user) {
        this._user.next(user);
        console.log('next');
      }
    }), map(user => {
      return !!user;
    }));
  }

  login(thisemail: string, thispassword: string) {
    return this.http.post<AuthResponseData>(
      `https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key=${environment.firebaseAPIKey}`,
    { email: thisemail, password: thispassword, returnSecureToken: true}
    ).pipe(tap(this.setUserData.bind(this)));
  }

  logout() {
    this._user = null;
  }

  signup(thisemail: string, thispassword: string) {
    console.log('HELLO3');
    return this.http.post<AuthResponseData>(
      `https://www.googleapis.com/identitytoolkit/v3/relyingparty/signupNewUser?key=${environment.firebaseAPIKey}`,
    { email: thisemail, password: thispassword, returnSecureToken: true}
    ).pipe(tap(this.setUserData.bind(this)
    ));
  }

  setUserData( userData: AuthResponseData) {
    console.log('set user data');
    const expirationTime = new Date( new Date().getTime() + (+userData.expiresIn * 1000));
    this._user.next(new User(userData.localId, userData.email, userData.idToken, expirationTime));
    this.storeAuthData(userData.localId, userData.idToken, expirationTime.toISOString(), userData.email);
  }

  private storeAuthData(userId: string, token: string, tokenExpirationDate: string, email: string) {
    console.log('start storage');
    const data = JSON.stringify({userId: userId, token: token, tokenExpirationDate: tokenExpirationDate, email: email});
    Plugins.Storage.set({key: 'authData', value: data});
    console.log('stored');
  }
}
