import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';



const routes: Routes = [
  // { path: '', redirectTo: 'tabs', pathMatch: 'full' },
  { path: 'auth', loadChildren: './auth/auth.module#AuthPageModule' },
 // { path: '', redirectTo: 'home', pathMatch: 'full' },
 // { path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
 // { path: 'tab-mission', loadChildren: './tab-mission/tab-mission.module#TabMissionPageModule', canLoad: [AuthGuard]  },
  //{ path: 'tab-timesheet', loadChildren: './tab-timesheet/tab-timesheet.module#TabTimesheetPageModule', canLoad: [AuthGuard]  },
  //{ path: 'tab-profile', loadChildren: './tab-profile/tab-profil.module#TabProfilePageModule', canLoad: [AuthGuard]  },
  //{ path: 'tab-money', loadChildren: './tab-money/tab-money.module#TabMoneyPageModule', canLoad: [AuthGuard]  },
  //{ path: 'tab-agenda', loadChildren: './tab-agenda/tab-agenda.module#TabAgendaPageModule', canLoad: [AuthGuard]  },
 // { path: 'tabs', loadChildren: './tabs/tabs.module#TabsPageModule' },
  { path: '', loadChildren: './tabs/tabs.module#TabsPageModule', canLoad: [AuthGuard] },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
